export function initMobileMenu() {
	const mobileMenuButton = document.getElementById('mobile-menu-button');
	if (mobileMenuButton) {
		const header = document.querySelector('header');
		mobileMenuButton.addEventListener('click', e => {
			e.preventDefault();
			document.body.classList.toggle('mobile-menu-visible');
		});
	}
}
