import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./app.scss";
import {initTestimonialCarousel} from "./js/testimonial-carousel";
import {initParticlesHeader} from "./js/particles";
import {initHeaderBg} from "./js/header-bg";
import {initMobileMenu} from "./js/mobile-menu";


document.addEventListener('DOMContentLoaded', () => {
    document.body.classList.add('js');
    initHeaderBg();
    initMobileMenu();
    initTestimonialCarousel();
    initParticlesHeader();
    // initContactAjax(); // not in use, not finished
});

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
