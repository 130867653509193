import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export function initTestimonialCarousel() {
    const sliderElement = document.querySelector('#testimonials .swiper');
    if (!sliderElement) {
        return;
    }
    const swiper = new Swiper(sliderElement, {
        modules: [Navigation, Pagination, Autoplay],
        spaceBetween: 40,
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
        },
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        breakpoints: {
            1024: {
                spaceBetween: 60
            }
        }
    });
}
