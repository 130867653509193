let headerElement;

export function initHeaderBg() {
    headerElement = document.querySelector('body > header');
    if (! headerElement) {
        return;
    }
    setHeaderBgClass();
    window.addEventListener('scroll', function() {
        setHeaderBgClass();
    });
}

function setHeaderBgClass() {
    headerElement.classList.toggle('bg-white', window.scrollY > 0);
}
